import { template as template_d0b758c2e6c343278e7d094feca69941 } from "@ember/template-compiler";
const FKControlMenuContainer = template_d0b758c2e6c343278e7d094feca69941(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
