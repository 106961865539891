import { template as template_58dd0f2fec4044f795d2752148e74ffd } from "@ember/template-compiler";
const FKText = template_58dd0f2fec4044f795d2752148e74ffd(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
