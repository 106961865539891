import { template as template_6d5d4f1ec6e44a3ba18e7f42c7bfd85e } from "@ember/template-compiler";
import Component from "@glimmer/component";
export default class FKControlCustom extends Component {
    static controlType = "custom";
    static{
        template_6d5d4f1ec6e44a3ba18e7f42c7bfd85e(`
    <div class="form-kit__control-custom">
      {{yield}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
