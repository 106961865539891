import { template as template_32c4e17f07eb414082e067147b4b2baf } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_32c4e17f07eb414082e067147b4b2baf(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
